import React from 'react';
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function WalletBalances({ wallet }) {
    const [balances, setBalances] = useState({});

    useEffect(() => {
        setInterval(() => {
            fetchBalances();
        }, 5000);
    }, []);

    const fetchBalances = async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
            }
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/associations/wallet/balances/', data);
            setBalances(response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <div>
            <div>
                <a href="#" className="btn btn-icon-text text-dark"><i className="flag-icon flag-icon-ug btn-icon-prepend"></i>UGX: {balances.ugx}</a>
                <hr />
                <a href="#" className="btn btn-icon-text text-dark"><i className="flag-icon flag-icon-ke btn-icon-prepend"></i>KES: {balances.kes}</a>
                <hr />
                <a href="#" className="btn btn-icon-text text-dark"><i className="flag-icon flag-icon-us btn-icon-prepend"></i>USD: {balances.usd}</a>
            </div>
        </div>
    );
}

export default WalletBalances;
