import React from 'react';
import AdminEvents from './adminevents';
import { useState } from 'react';
import AdminContributions from './admincontributions';
import CreateEvent from './createEvent';
import BarAndPieChart from './bar';

function AdminDashboardContent({association, setAssociation, userMode, setuserMode, activeTab, setActiveTab, events, setEvents, adminContributions, setAdminContributions, loading, setLoading, Refresh}) {
    

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-sm-flex justify-content-between align-items-center transaparent-tab-border">
          <ul className="nav nav-tabs tab-transparent" role="tablist">
            <li className="nav-item">
              <a className={activeTab === 'events' ? "nav-link active" : "nav-link"} id="home-tab" data-bs-toggle="tab" href="#" role="tab" aria-selected={activeTab === 'events'} onClick={()=> setActiveTab('events')}>Events</a>
            </li>
            <li className="nav-item">
              <a className={activeTab === 'contributions' ? "nav-link active" : "nav-link"} id="business-tab" data-bs-toggle="tab" href="#business-1" role="tab" aria-selected={activeTab === 'contributions'} onClick={()=> setActiveTab('contributions')}>Contributions</a>
            </li>
            <li className="nav-item">
              <a className={activeTab === 'create-event' ? "nav-link active" : "nav-link"} id="performance-tab" data-bs-toggle="tab" href="#" role="tab" aria-selected={activeTab === 'create-event'} onClick={()=> setActiveTab('create-event')}>Create Event</a>
            </li>
            <li className="nav-item">
              <a className={activeTab === 'performance' ? "nav-link active" : "nav-link"} id="performance-tab" data-bs-toggle="tab" href="#" role="tab" aria-selected={activeTab === 'performance'} onClick={()=> setActiveTab('performance')}>Performance</a>
            </li>
          </ul>
          <div className="d-md-block d-none">
            <a href="#" className="text-light p-1"><i classNamtse="mdi mdi-view-dashboard"></i></a>
            <a href="#" className="text-light p-1"><i className="mdi mdi-dots-vertical"></i></a>
          </div>
        </div>
        <div className="tab-content tab-transparent-content">
          <div className="tab-pane fade show active" id="business-1" role="tabpanel" aria-labelledby="business-tab">
            <div className="row">
                {activeTab === 'events' && <AdminEvents events={events} setEvents={setEvents} loading={loading} setLoading={setLoading} Refresh={Refresh} />}
                {activeTab === 'contributions' && <AdminContributions adminContributions={adminContributions} setAdminContributions={setAdminContributions} />}
                {activeTab === 'create-event' && <CreateEvent activeTab={activeTab} setActiveTab={setActiveTab} loading={loading} setLoading={setLoading} Refresh={Refresh} />}
                {activeTab === 'performance' && <BarAndPieChart/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardContent;
