import React from 'react';
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function ForgotPassword({message, setAction}) {
    const [email, setEmail] = useState('email');

    const handleReset = async() => {

    }

    return (
        <div className="auth-form-light text-left p-5 text-dark">
            <div style={{ fontWeight: 'bold', color: 'green', fontSize: 30 }} className="brand-logo">
                BENEVOFY
            </div>
            {message.message != undefined &&
                <div style={{ borderRadius: 5 }} className="bg-success p-2 mb-2 text-white">
                    {message.message}
                </div>
            }
            <h6 className="font-weight-light">Enter email to continue.</h6>
            <form className="pt-3">
                <div className="form-group">
                    <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Email" />
                </div>
                <div className="mt-3 d-flex justify-content-between">
                    <a href="#" style={{ backgroundColor: 'green', color: 'white' }} className="btn btn-block btn-lg font-weight-medium auth-form-btn" onClick={handleReset}>Continue</a>
                    <a href="#" className="btn btn-sm btn-dark" style={{ alignContent: 'center' }} onClick={() => setAction('login')}><i className="mdi mdi-arrow-left"></i></a>
                </div>

            </form>
        </div>
    );
}

export default ForgotPassword;
