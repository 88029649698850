import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function Members({ members, userMode, setuserMode, biodata, loading, setLoading, toggle, modalcontent, setModalcontent }) {

  const handleMakeAdmin = async (member) => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
        member: member.id    
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/auth/make-member-admin/', data);
      if (response.data.success) {
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }


  const handleRemoveAdmin = async (member) => {
    setLoading(true);
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
        member: member.id    
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/auth/remove-admin/', data);
      if (response.data.success) {
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }

  const handleAddMember = ()=> {
    toggle();
    setModalcontent('add-member');
  }

  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h4 className="card-title">Members</h4>
            </div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> Avatar </th>
                  <th> Names </th>
                  <th> Email </th>
                  <th> Contributions </th>
                  <th> Date Joined </th>
                  {userMode == 'admin' &&
                    <th> Actions </th>
                  }
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr>
                    <td className="py-1">
                      <img src="/male.png" alt="image" />
                    </td>
                    <td> {member.names} </td>
                    <td>
                      {member.email}
                    </td>
                    <td> {member.contributions} </td>
                    <td> {member.date_joined} </td>
                    {userMode == 'admin' &&
                      <td>
                        <div className="btn-group">
                          {!member.admin &&
                            <a href="#" className="btn btn-sm btn-success" onClick={()=> handleMakeAdmin(member)}>Make Admin</a>
                          }
                          {member.admin &&
                            <a href="#" className="btn btn-sm btn-success" onClick={()=> handleRemoveAdmin(member)}>Remove Admin</a>
                          }
                          <a href="#" className="btn btn-sm btn-danger">Suspend</a>
                        </div>
                      </td>
                    }
                  </tr>
                ))}
                {/* Additional table rows go here */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>

  );
}

export default Members;
