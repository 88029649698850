import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

function Sidebar({ authenticated, setAuthenticated, activePage, setactivePage, setModalcontent, toggle, userMode }) {

  const handleLogout = async () => {
    try {
      await AsyncStorage.removeItem('token');
      console.log('Data removed successfully');
    } catch (error) {
      console.error('Error removing data:', error);
    }
    setAuthenticated(false);
  }

  const handleChangePassword = () => {
    setModalcontent('change-password');
    toggle();
  }

  const handleReportIssue = () => {
    setModalcontent('report-issue');
    toggle();
  }

  const handleAppdl = () => {
    setModalcontent('app-download');
    toggle();
  }

  const BioData = () => {
    setModalcontent('biodata');
    toggle();
  }

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-category">Main</li>
        <li className={activePage === 'dashboard' ? ("nav-item active") : ("nav-item")} onClick={() => setactivePage('dashboard')}>
          <a className="nav-link" href="#">
            <span className="icon-bg"><i className="mdi mdi-home"></i></span>
            <span className="menu-title">Dashboard</span>
          </a>
        </li>
        {userMode === 'admin' &&
          <li className={activePage === 'members' ? ("nav-item active") : ("nav-item")}>
            <a className="nav-link" href="#" onClick={() => setactivePage('members')}>
              <span className="icon-bg"><i className="mdi mdi-contacts menu-icon"></i></span>
              <span className="menu-title">Members</span>
            </a>
          </li>
        }
        {userMode === 'admin' &&
          <li className={activePage === 'savings' ? ("nav-item active") : ("nav-item")}>
            <a className="nav-link" href="#" onClick={() => setactivePage('savings')}>
              <span className="icon-bg"><i className="mdi mdi-briefcase menu-icon"></i></span>
              <span className="menu-title">Savings</span>
            </a>
          </li>
        }
        {userMode === 'admin' &&
          <li className={activePage === 'loan-requests' ? ("nav-item active") : ("nav-item")}>
            <a className="nav-link" href="#" onClick={() => setactivePage('loan-requests')}>
              <span className="icon-bg"><i className="mdi mdi-cash-multiple menu-icon"></i></span>
              <span className="menu-title">Loan Requests</span>
            </a>
          </li>
        }
        {userMode === 'member' &&
          <li className={activePage === 'associations' ? ("nav-item active") : ("nav-item")}>
            <a className="nav-link" href="#" onClick={() => setactivePage('associations')}>
              <span className="icon-bg"><i className="mdi mdi-account-multiple"></i></span>
              <span className="menu-title">Associations</span>
            </a>
          </li>
        }
        <li className={activePage === 'issue' ? ("nav-item active") : ("nav-item")}>
          <a className="nav-link" href="#" onClick={handleReportIssue}>
            <span className="icon-bg"><i className="mdi mdi-bug"></i></span>
            <span className="menu-title">Report Issue</span>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" data-bs-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
            <span className="icon-bg"><i className="mdi mdi-account menu-icon"></i></span>
            <span className="menu-title">Account</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="auth">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <a className="nav-link" href="#" onClick={handleChangePassword}> Change Password </a></li>
              <li className="nav-item"> <a className="nav-link" href="#" onClick={BioData}> Biodata </a></li>

            </ul>
          </div>
        </li>
        <li className="nav-item documentation-link">
          <a className="nav-link bg-success" href="#" onClick={handleAppdl}>
            <span className="icon-bg">
              <i style={{ color: 'white' }} className="mdi mdi-download menu-icon"></i>
            </span>
            <span className="menu-title">Download APP</span>
          </a>
        </li>
        <li className="nav-item sidebar-user-actions">
          <div className="sidebar-user-menu">
            <a onClick={handleLogout} href="#" className="nav-link"><i className="mdi mdi-logout menu-icon"></i>
              <span className="menu-title">Log Out</span></a>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Sidebar;
