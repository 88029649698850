import logo from './logo.svg';
import LoginPage from './components/login';
import Dashboard from './components/dashboard'
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import ExampleModal from './components/modal';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [association, setAssociation] = useState({});
  const [userMode, setuserMode] = useState('member');
  const [activeTab, setActiveTab] = useState('events');
  const [biodata, setBiodata] = useState({});
  const [wallet, setWallet] = useState({})
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [modal, setModal] = useState(false);
  const [activePage, setactivePage] = useState('dashboard');
  const [events, setEvents] = useState([]);
  const [waitFetch, setwaitFetch] = useState(false);
  const [members, setMembers] = useState([])
  const [memberContributions, setMemberContributions] = useState([]);
  const [adminContributions, setAdminContributions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [newAlert, setnewAlert] = useState('');


  useEffect(() => {
    Refresh();
  }, []); // empty dependency array


  const Refresh = () => {
    setLoading(true);
    checkLoginstatus();
    fetchBiodata();
    fetchEvents();
    fetchMembers();
    fetchMemberContributions();
    fetchAdminContributions();
    fetchNotifications();
  }



  const checkLoginstatus = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token !== null) {
        setAuthenticated(true);
        setToken(token);
      } else {
        setAuthenticated(false);
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  }

  const fetchBiodata = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/auth/biodata/', data);
      if (response.data.success) {
        setBiodata(response.data);
        setAssociation(response.data.current_association)
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const fetchMemberContributions = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/contributions/member/', data);
      if (response.data.success) {
        setMemberContributions(response.data.contributions);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }

  const fetchAdminContributions = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/contributions/admin/', data);
      if (response.data.success) {
        setAdminContributions(response.data.contributions);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }

  const fetchEvents = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/events/', data);
      if (response.data.success) {
        setEvents(response.data.events);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }

  const fetchNotifications = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/notifications/', data);
      if (response.data.success) {
        setNotifications(response.data.notifications);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }

  const fetchMembers = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/members/', data);
      if (response.data.success) {
        setMembers(response.data.members);
        setLoading(false);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  }

  return (
    <div>
      {authenticated ? (
        <Dashboard association={association} setAssociation={setAssociation} userMode={userMode} setuserMode={setuserMode} activeTab={activeTab} setActiveTab={setActiveTab} authenticated={authenticated} setAuthenticated={setAuthenticated} biodata={biodata} wallet={wallet} setWallet={setWallet} selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} modal={modal} setModal={setModal} activePage={activePage} setactivePage={setactivePage} events={events} setEvents={setEvents} waitFetch={waitFetch} setwaitFetch={setwaitFetch} members={members} setMembers={setMembers} memberContributions={memberContributions} setMemberContributions={setMemberContributions} adminContributions={adminContributions} setAdminContributions={setAdminContributions} loading={loading} setLoading={setLoading} notifications={notifications} setNotifications={setNotifications} newAlert={newAlert} setNewalert={setnewAlert} Refresh={Refresh} />
      ) : (
        <LoginPage setAuthenticated={setAuthenticated} loading={loading} setLoading={setLoading} checkLoginstatus={checkLoginstatus} setNewalert={setnewAlert} biodata={biodata} Refresh={Refresh} />
      )}
    </div>
  );
}

export default App;
