import React, { useState } from 'react';

const Deposit = ({depositAmount, setdepositAmount}) => {

    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="number" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Phone Number" onChange={(e)=> setdepositAmount(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="number" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Amount" onChange={(e)=> setdepositAmount(e.target.value)} />
            </div>
        </form>
    );
};

export default Deposit;

