import React from 'react';
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function Notification({notification}) {

    return (
        <div className='text-dark'>
            <p>{notification?.description}</p>
        </div>
    );
}

export default Notification;
