import React, { useState } from 'react';

const SetWalletPin = ({pin, setPin}) => {

    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Set Pin" onChange={(e)=> setPin(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Confirm Pin" />
            </div>
        </form>
    );
};

export default SetWalletPin;

