import React, { useState } from 'react';
import MemberDropdown from './memberdropdown';

const Pay = ({ selectedEvents, setSelectedEvents, members, setMembers, biodata, amounts, setAmounts, valid, setValid, member, setMember, phone, setPhone }) => {

    const updateAmounts = (amount, index) => {
        const event_ = selectedEvents[index]
        if (event_.minimum_contribution > amount) {
            setValid(false);
        }
        else {
            setValid(true)
        }
        const newAmounts = [...amounts]; // Create a copy of the original amounts array
        newAmounts[index] = amount; // Update the value at the specified index
        setAmounts(newAmounts); // Set the state with the updated amounts array
    }

    return (
        <form className="pt-3">
            <MemberDropdown members={members} label={'Select Member to pay for'} default_={biodata.id} setMember={setMember} />
            {selectedEvents.map((event, index) => (
                <div className="form-group" key={index}>
                    <label htmlFor="" className='text-dark'>{event.title} {'[' + event.minimum_contribution + ' Minimum' + ']'}</label>
                    <input type="number" className="form-control form-control-lg" id={`event-${index}`} value={amounts[index]} placeholder="Allocate Amount" min={event.minimum_contribution} onChange={(e) => updateAmounts(e.target.value, index)} />
                </div>
            ))}
            <div className="form-group">
                <input type="number" className="form-control form-control-lg text-dark" id="exampleInputEmail1" placeholder="Enter Mobile Number e.g. 256789592019" onChange={(e)=> setPhone(e.target.value)} />
            </div>
        </form>
    );
};

export default Pay;
