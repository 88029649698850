import React from 'react';
import Select from 'react-select';

const MemberDropdown = ({ label, members, default_, setMember }) => {
  // Transform members data into options expected by react-select
  const options = members.map((member) => ({
    value: member.id,
    label: member.names
  }));

  return (
    <div className="form-group">
      <label>{label}</label>
      <Select
        styles={{ control: styles => ({ ...styles, width: '100%' }) }}
        options={options}
        onChange={(selectedOption) => setMember(selectedOption ? selectedOption.value : '')}
        defaultValue={options.find(option => option.value === default_)}
        placeholder={label}
        isClearable={true}
      />
    </div>
  );
}

export default MemberDropdown;
