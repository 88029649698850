import React from 'react';

function DashboardHeader({ association, setAssociation, userMode, setuserMode, wallet, setWallet, modal, setModal, toggle, modalcontent, setModalcontent }) {

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Id copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
        alert('Failed to copy text!');
      });
  };

  const checkWalletBalance = ()=> {
    setModalcontent('wallet-balances');
    toggle();
  }

  return (
    <div className="d-xl-flex justify-content-between align-items-start">
      <h2 className="text-dark font-weight-bold mb-2">{association.name} {userMode === 'admin' ? ('Administrator') : ('Member')}'s dashboard</h2>
      {userMode === 'admin' &&
        <div className="d-sm-flex justify-content-xl-between align-items-center mb-2">
          <div className="btn-group">
            <a href="#" className="btn btn-outline-dark btn-icon-text" onClick={() => copyToClipboard(association.association_id)}>
              <i className="mdi mdi-content-copy btn-icon-prepend"></i>
              {association.association_id}
            </a>
            <button type="button" className="btn btn-outline-dark btn-icon-text" onClick={checkWalletBalance}>
              <i className="mdi mdi-wallet btn-icon-prepend"></i> Wallet Balances
            </button>
          </div>
        </div>
      }
    </div>
  );
}

export default DashboardHeader;
