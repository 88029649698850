import React, { useState } from 'react';

const RegisterInNew = () => {

    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="text" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Association Id" />
            </div>
        </form>
    );
};

export default RegisterInNew;

