import React from 'react';
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function CreateAccount({ message, setAction, setMessage, setLoading }) {
    const [associationid, setAssociationId] = useState('');
    const [association, setAssociation] = useState({});
    const [first_name, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('1');
    const [formValid, setformValid] = useState(true);
    const [gender, setGender] = useState('');

    const findAssociation = async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                association_id: associationid
            }
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/associations/find/', data);
            if (response.data.success) {
                setAssociation(response.data);
                setformValid(false);
            }
            else {
                setMessage(response.data);
                setTimeout(() => {
                    setMessage({});
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const HandleSubmit = async () => {
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                association_id: associationid,
                first_name: first_name,
                last_name: lastname,
                gender: gender,
                email: email,
                number: contact,
                password: password
            }
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/auth/register/', data);
            if (response.data.success) {
                setMessage(response.data)
                setAction('login')
            }
            else {
                setMessage(response.data);
                setTimeout(() => {
                    setMessage({});
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    return (
        <div className="auth-form-light text-left p-5 text-dark">

            {message.message != undefined &&
                <div style={{ borderRadius: 5 }} className="bg-success p-2 mb-2 text-white">
                    {message.message}
                </div>
            }
            {association.name != undefined &&
                <div className="text-center">
                    <img width={100} src={'https://api.edu-metrics.com' + association.icon} alt="" srcset="" />
                </div>
            }
            <div style={{ fontWeight: 'bold', color: 'green', fontSize: 30 }} className="brand-logo">
                BENEVOFY
            </div>
            <h4 style={{ color: 'orange' }}>Hello! let's get started</h4>
            {association.name === undefined &&
                <h6 className="font-weight-light">Enter association ID to continue.</h6>
            }
            {association.name &&
                <h6 className="font-weight-light">Enter your details to continue.</h6>
            }
            <form className="pt-3">
                {association.name == undefined &&
                    <div className="form-group">
                        <input type="text" onChange={(e) => setAssociationId(e.target.value)} className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Association ID" />
                    </div>
                }
                {association.name != undefined &&
                    <>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setFirstName(e.target.value)} className="form-control form-control-lg" placeholder="First Name" />
                        </div>
                        <div className="form-group">
                            <input type="text" onChange={(e) => setLastName(e.target.value)} className="form-control form-control-lg" placeholder="Last Name" />
                        </div>
                        <div className="form-group">
                            <select onChange={(e)=> setGender(e.target.value)} name="" id="" className="form-control">
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" placeholder="email" />
                        </div>
                        <div className="form-group">
                            <input type="email" onChange={(e) => setContact(e.target.value)} className="form-control form-control-lg" placeholder="contact" />
                        </div>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg" placeholder="Password" />
                        </div>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setconfirmPassword(e.target.value)} className="form-control form-control-lg" placeholder="Confirm Password" />
                        </div>
                    </>
                }
                <div className="mt-3 d-flex justify-content-between">
                    {first_name.length > 2 && lastname.length > 2 && email.length > 2 && contact.length === 10 && password.length > 5 && password === confirmPassword && gender.length > 1 &&
                        <a href="#" style={{ backgroundColor: 'green', color: 'white' }} className="btn btn-block btn-lg font-weight-medium auth-form-btn" onClick={HandleSubmit}>Submit</a>
                    }
                    {formValid &&
                        <a href="#" style={{ backgroundColor: 'green', color: 'white' }} className="btn btn-block btn-lg font-weight-medium auth-form-btn" onClick={findAssociation}>Continue</a>
                    }
                    <a href="#" className="btn btn-sm btn-dark" style={{ alignContent: 'center' }} onClick={() => setAction('login')}><i className="mdi mdi-arrow-left"></i></a>
                </div>

            </form>
        </div>
    );
}

export default CreateAccount;
