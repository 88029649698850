import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function Associations({ setModalcontent, toggle, biodata }) {

    const handleEnterAssociationId = () => {
        setModalcontent('association-id');
        toggle();
    }

    return (
        <>
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h4 className="card-title">Associations</h4>
                            <a style={{ height: 29 }} href="#" className="btn btn-sm btn-success" onClick={handleEnterAssociationId}>REGISTER IN NEW</a>

                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th> Icon </th>
                                    <th> Name </th>
                                    <th> Date Initiated </th>
                                </tr>
                            </thead>
                            <tbody>
                                {biodata.associations?.map((association) => (
                                    <tr>
                                        <td className="py-1">
                                            <img src={'https://api.edu-metrics.com' + association.icon} alt="image" />
                                        </td>
                                        <td>{association.name} </td>
                                        <td>{association.date_initiated}</td>
                                    </tr>
                                ))}
                                {/* Additional table rows go here */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Associations;
