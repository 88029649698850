import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import CreateAccount from './createeaccount';
import ForgotPassword from './forgotpassword';

function LoginPage({ setAuthenticated, loading, setLoading, checkLoginstatus, setNewalert, biodata, Refresh }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({});
  const [action, setAction] = useState('login');
  

  useEffect(() => {
    checkLoginstatus()
  });

  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = {
        email: email,
        password: password
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/auth/login/', data);
      if (response.data.success) {
        try {
          await AsyncStorage.setItem('token', response.data.token);
        } catch (error) {
          console.error('Error storing data:', error);
        }
        setAuthenticated(true);
        Refresh();
        setNewalert('Happy to have you back '+response.data.first_name+' 🙂🫂!')
        setTimeout(() => {
          setNewalert('');
        }, 5000);
      }
      else {
        setMessage({ message: response.data.message });
        setLoading(false);
        setTimeout(() => {
          setMessage('');
        }, 3000);

      }
    } catch (error) {
      console.error('Error:', error);
      // Handle errors
    }
  }


  return (
    <div className="container-fluid page-body-wrapper full-page-wrapper" >
      <div className="content-wrapper d-flex align-items-center auth" style={{ backgroundImage: "url('/gorilla.jpg')", backgroundSize: 'cover' }}>
        <div className="row flex-grow" style={{ opacity: 0.9 }}>
          <div className={loading ? "col-lg-4 mx-auto text-center" : "col-lg-4 mx-auto"}>
            {loading ? (
              <div className="spinner-border" style={{ width: 200, height: 200, color: 'white' }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                {action === 'login' &&
                  <div className="auth-form-light text-left p-5 text-dark">
                    <div style={{ fontWeight: 'bold', color: 'green', fontSize: 30 }} className="brand-logo">
                      BENEVOFY
                    </div>
                    {message.message != undefined &&
                      <div style={{ borderRadius: 5 }} className="bg-success p-2 mb-2 text-white">
                        {message.message}
                      </div>
                    }
                    <h4 style={{ color: 'orange' }}>Hello! let's get started</h4>
                    <h6 className="font-weight-light">Sign in to continue.</h6>
                    <form className="pt-3">
                      <div className="form-group">
                        <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username" />
                      </div>
                      <div className="form-group">
                        <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                      </div>
                      <div className="mt-3 d-flex justify-content-between">
                        <a href="#" style={{ backgroundColor: 'green', color: 'white' }} className="btn btn-block btn-lg font-weight-medium auth-form-btn" onClick={handleLogin}>SIGN IN</a>
                      </div>
                      <div className="my-2 d-flex justify-content-between align-items-center">

                        <a href='#' onClick={()=> setAction('reset-password')} style={{ color: 'green' }} className="form-check-label">
                          Forgot Password?
                        </a>
                      </div>
                      <p className="sign-up">Don't have an Account?<a href="#" onClick={() => setAction('create-account')}> Register</a></p>

                    </form>
                  </div>
                }
                {action === 'create-account' &&
                  <CreateAccount message={message} setAction={setAction} setMessage={setMessage} setLoading={setLoading} />
                }
                {action === 'reset-password' &&
                  <ForgotPassword message={message} setAction={setAction} />
                }
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
