import React, { useState, useEffect } from 'react';
import Navbar from './nav';
import Sidebar from './sidebar';
import DashboardHeader from './dashboardheader';
import MemberDashboardContent from './membersdashboardcontent';
import AdminDashboardContent from './admindasboardcontent';
import Mymodal from './modal';
import Members from './members';
import Associations from './associations';

function Dashboard({ association, setAssociation, userMode, setuserMode, activeTab, setActiveTab, authenticated, setAuthenticated, biodata, wallet, setWallet, selectedEvents, setSelectedEvents, modal, setModal, activePage, setactivePage, events, setEvents, waitFetch, setwaitFetch, members, setMembers, memberContributions, setMemberContributions, adminContributions, setAdminContributions, loading, setLoading, notifications, setNotifications, newAlert, setNewalert, Refresh }) {
  const [modalcontent, setModalcontent] = useState('setwalletpin');
  const toggle = () => setModal(!modal);
  const [amounts, setAmounts] = useState([]);
  const [member, setMember] = useState('');
  const [note, setNotification] = useState({});


  useEffect(() => {
    setMember(biodata.id);
  }, []); // empty dependency array


  return (
    <>
      <Navbar association={association} setAssociation={setAssociation} userMode={userMode} setuserMode={setuserMode} activeTab={activeTab} biodata={biodata} loading={loading} setLoading={setLoading} setSelectedEvents={setSelectedEvents} notifications={notifications} setModalcontent={setModalcontent} toggle={toggle} setNotification={setNotification} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar authenticated={authenticated} setAuthenticated={setAuthenticated} activePage={activePage} setactivePage={setactivePage} setModalcontent={setModalcontent} toggle={toggle} userMode={userMode} />
        <div className="main-panel">
          <div className={loading ? "content-wrapper text-center" : "content-wrapper"} style={{ alignContent: loading ? 'center' : '' }}>
            {!loading ? (
              <>
                {newAlert != '' &&
                  <div className='bg-success mb-3 p-3 text-white'>{newAlert}</div>
                }
                <Mymodal modal={modal} setModal={setModal} toggle={toggle} modalcontent={modalcontent} setModalcontent={setModalcontent} selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} waitFetch={waitFetch} setwaitFetch={setwaitFetch} members={members} setMembers={setMembers} biodata={biodata} amounts={amounts} setAmounts={setAmounts} member={member} setMember={setMember} note={note} setNewalert={setNewalert} />
                {activePage === 'members' &&
                  <Members members={members} userMode={userMode} setuserMode={setuserMode} biodata={biodata} loading={loading} setLoading={setLoading} toggle={toggle} modalcontent={modalcontent} setModalcontent={setModalcontent} />
                }
                {activePage === 'dashboard' &&
                  <>
                    <DashboardHeader association={association} setAssociation={setAssociation} userMode={userMode} setuserMode={setuserMode} wallet={wallet} setWallet={setWallet} modal={modal} setModal={setModal} toggle={toggle} modalcontent={modalcontent} setModalcontent={setModalcontent} />
                    {userMode == 'admin' ? (
                      <AdminDashboardContent activeTab={activeTab} setActiveTab={setActiveTab} events={events} setEvents={setEvents} adminContributions={adminContributions} setAdminContributions={setAdminContributions} loading={loading} setLoading={setLoading} Refresh={Refresh} />
                    ) : (
                      <MemberDashboardContent activeTab={activeTab} setActiveTab={setActiveTab} selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} modalcontent={modalcontent} setModalcontent={setModalcontent} toggle={toggle} events={events} setEvents={setEvents} amounts={amounts} setAmounts={setAmounts} memberContributions={memberContributions} setMemberContributions={setMemberContributions} />
                    )}
                  </>
                }
                {activePage === 'associations' &&
                  <Associations setModalcontent={setModalcontent} toggle={toggle} biodata={biodata} />
                }
              </>
            ) : (
              <div className="spinner-border" style={{ width: 100, height: 100, color: 'green' }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
