import React, { useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

const CreateEvent = ({ activeTab, setActiveTab, loading, setLoading, Refresh }) => {
    const [title, setTitle] = useState('');
    const [amount_required, setAmountrequired] = useState('');
    const [minimum_payment, setMinimumpayment] = useState('');
    const [closing_date, setClosingdate] = useState('');


    const handleCreateEvent = async () => {
        setLoading(true);
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                title: title,
                amount_required: amount_required,
                minimum_contribution: minimum_payment,
                closing_date: closing_date
            }
            const response = await axios.post('https://api.edu-metrics.com/api/events/create/', data);
            if (response.data.success) {
                setActiveTab('events');
                Refresh();
            }
            else {
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle errors
        }
    }


    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title">Create Event</h4>
                <form className="forms-sample">
                    <div className="form-group">
                        <label htmlFor="exampleInputUsername1">Title</label>
                        <input type="text" className="form-control" id="exampleInputUsername1" placeholder="Event Title" onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Amount Required</label>
                        <input type="number" className="form-control" id="exampleInputEmail1" placeholder="" onChange={(e) => setAmountrequired(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Minimum Payment</label>
                        <input type="number" className="form-control" id="exampleInputEmail1" placeholder="" onChange={(e) => setMinimumpayment(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Closing Date</label>
                        <input type="date" className="form-control" id="exampleInputPassword1" placeholder="" onChange={(e) => setClosingdate(e.target.value)} />
                    </div>
                    {title.length > 0 && amount_required.length > 0 && minimum_payment.length > 0 && closing_date.length > 0 &&
                        <div className="d-flex justify-content-between">
                            <button type="submit" style={{ backgroundColor: 'green' }} className="btn me-2 text-white" onClick={handleCreateEvent}>Create</button>
                        </div>
                    }
                </form>
            </div>
        </div>
    );
};

export default CreateEvent;
