import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SetWalletPin from './setwalletpin';
import Deposit from './deposit';
import Withdraw from './withdraw';
import Transfer from './transfer';
import Pay from './payforevents';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import AddMember from './addmember';
import RegisterInNew from './registerinnew';
import ChangePassword from './changepassword';
import ReportIssue from './reportissue';
import WalletBalances from './walletbalances';
import Notification from './notification';
import AppDl from './appinfo';
import Biodata from './biodata';

const Mymodal = ({ modal, setModal, toggle, modalcontent, setModalcontent, selectedEvents, setSelectedEvents, waitFetch, setwaitFetch, members, setMembers, biodata, amounts, setAmounts, member, setMember, note , setNewalert}) => {
    const [modalLoading, setModalLoading] = useState(false);
    const [pin, setPin] = useState('');
    const [depositAmount, setdepositAmount] = useState(0);
    const [valid, setValid] = useState(true);
    const [error, setError] = useState('');
    const [reciever, setReciever] = useState(0);
    const [transferAmount, setTransferAmount] = useState(0);
    const [phone, setPhone] = useState('');
    const [issue, setIssue] = useState('');
    const [oldpassword, setOldPassword] = useState('');
    const [newpassword, setNewPassword] = useState('');

    const handleContinue = async () => {
        setModalLoading(true);
        setwaitFetch(true);
        if (modalcontent === 'setwalletpin') {
            try {
                const token = await AsyncStorage.getItem('token');
                const data = {
                    token: token,
                    pin: pin
                }
                // Make POST request using Axios
                const response = await axios.post('https://api.edu-metrics.com/api/wallets/set-pin/', data);
                if (response.data.success) {
                    toggle();
                    setModalLoading(false);
                }
                else {
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle errors
            }
        }

        else if (modalcontent === 'pay') {
            try {
                const token = await AsyncStorage.getItem('token');
                const data = {
                    token: token,
                    amounts: amounts,
                    member: biodata.id,
                    events: selectedEvents.map((event) => event.id),
                    phone: phone
                }
                // Make POST request using Axios
                const response = await axios.post('https://api.edu-metrics.com/api/events/pay/', data);
                if (response.data.success) {
                    setSelectedEvents([]);
                    toggle();
                    setModalLoading(false);
                    setNewalert('Your payment has been initiated!')
                    setTimeout(() => {
                        setNewalert('');
                    }, 3000);
                }
                else {
                    setError(response.data.message);
                    setModalLoading(false);
                    setTimeout(() => {
                        setError('');
                    }, 3000);
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle errors
            }
        }

        else if (modalcontent === 'report-issue') {
            try {
                const token = await AsyncStorage.getItem('token');
                const data = {
                    token: token,
                    issue: issue
                }
                // Make POST request using Axios
                const response = await axios.post('https://api.edu-metrics.com/api/issues/raise/', data);
                if (response.data.success) {
                    setNewalert(response.data.message);
                    toggle();
                    setModalLoading(false);
                    setTimeout(() => {
                        setNewalert('');
                    }, 3000);
                }
                else {
                    setError(response.data.message);
                    setModalLoading(false);
                    setTimeout(() => {
                        setError('');
                    }, 3000);
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle errors
            }
        }
        else if (modalcontent === 'change-password') {
            try {
                const token = await AsyncStorage.getItem('token');
                const data = {
                    token: token,
                    oldpassword: oldpassword,
                    newpassword: newpassword
                }
                // Make POST request using Axios
                const response = await axios.post('https://api.edu-metrics.com/api/auth/change-password/', data);
                if (response.data.success) {
                    setNewalert(response.data.message);
                    toggle();
                    setModalLoading(false);
                    setTimeout(() => {
                        setNewalert('');
                    }, 3000);
                }
                else {
                    setError(response.data.message);
                    setModalLoading(false);
                    setTimeout(() => {
                        setError('');
                    }, 3000);
                }
            } catch (error) {
                console.error('Error:', error);
                // Handle errors
            }
        }
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader className='text-dark' toggle={toggle}>
                    {modalcontent === 'setwalletpin' &&
                        'Set Wallet Pin'
                    }
                    {modalcontent === 'deposit' &&
                        'Deposit to Wallet'
                    }
                    {modalcontent === 'withdraw' &&
                        'Withdraw From Wallet'
                    }
                    {modalcontent === 'transfer' &&
                        'Send money to another member.'
                    }
                    {modalcontent === 'pay' &&
                        'Pay for Events'
                    }
                    {modalcontent === 'add-member' &&
                        'Add Member'
                    }
                    {modalcontent === 'association-id' &&
                        'Register in Association'
                    }
                    {modalcontent === 'change-password' &&
                        'Change Password'
                    }
                    {modalcontent === 'report-issue' &&
                        'Report Issue'
                    }
                    {modalcontent === 'wallet-balances' &&
                        'Wallet Balances'
                    }
                    {modalcontent === 'notification' &&
                        note.title
                    }
                    {modalcontent === 'app-download' &&
                        'Mobile App'
                    }
                    {modalcontent === 'biodata' &&
                        'Your Biodata'
                    }
                </ModalHeader>
                <ModalBody>
                    {error.length > 0 &&
                        <div style={{ borderRadius: 10 }} className='bg-danger p-2 mb-2 text-white'>
                            {error}
                        </div>
                    }
                    {modalcontent === 'setwalletpin' &&
                        <SetWalletPin pin={pin} setPin={setPin} />
                    }
                    {modalcontent === 'deposit' &&
                        <Deposit depositAmount={depositAmount} setdepositAmount={setdepositAmount} />
                    }
                    {modalcontent === 'withdraw' &&
                        <Withdraw />
                    }
                    {modalcontent === 'pay' &&
                        <Pay selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} members={members} setMembers={setMembers} biodata={biodata} amounts={amounts} setAmounts={setAmounts} valid={valid} setValid={setValid} member={member} setMember={setMember} phone={phone} setPhone={setPhone} />
                    }
                    {modalcontent === 'add-member' &&
                        <AddMember />
                    }
                    {modalcontent === 'association-id' &&
                        <RegisterInNew />
                    }
                    {modalcontent === 'change-password' &&
                        <ChangePassword setNewPassword={setNewPassword} setOldPassword={setOldPassword} setValid={setValid} newpassword={newpassword} />
                    }
                    {modalcontent === 'report-issue' &&
                        <ReportIssue biodata={biodata} setIssue={setIssue} />
                    }
                    {modalcontent === 'wallet-balances' &&
                        <WalletBalances biodata={biodata} />
                    }
                    {modalcontent === 'notification' &&
                        <Notification notification={note} />
                    }
                    {modalcontent === 'app-download' &&
                        <AppDl />
                    }
                    {modalcontent === 'biodata' &&
                        <Biodata />
                    }
                </ModalBody>
                {modalcontent != 'wallet-balances' && modalcontent != 'notification' && modalcontent != 'app-download' &&
                    <ModalFooter>
                        {modalLoading &&
                            <div className="spinner-border" style={{ width: 30, height: 30, color: 'green' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        {valid &&
                            <Button style={{ backgroundColor: 'green' }} onClick={handleContinue}>Continue</Button>
                        }
                    </ModalFooter>
                }

            </Modal>
        </div>
    );
};

export default Mymodal;