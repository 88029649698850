import React, { useState, useEffect } from 'react';

const ChangePassword = ({ setNewPassword, setOldPassword, setValid, newpassword }) => {

    useEffect(() => {
        setValid(false);
    }, []); // empty dependency array

    const Validate = (value)=> {
        if (value === newpassword && newpassword.length >= 6) {
            setValid(true);
        }
        else {
            setValid(false);
        }
    }


    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Old Password" onChange={(e) => setOldPassword(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="New Password" onChange={(e) => setNewPassword(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Confirm Password" onChange={(e)=> Validate(e.target.value)} />
            </div>
        </form>
    );
};

export default ChangePassword;

