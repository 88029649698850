import React, { useState } from 'react';

const Withdraw = () => {

    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="number" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Phone" />
            </div>
            <div className="form-group">
                <input type="number" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Amount" />
            </div>
            <div className="form-group">
                <input type="password" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Pin" />
            </div>
        </form>
    );
};

export default Withdraw;

