import React from 'react';

const AdminContributions = ({ adminContributions, setAdminContributions }) => {
    return (
        <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Member's Contributions</h4>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th> Member </th>
                                <th> Event </th>
                                <th> Amount </th>
                                <th> Date </th>
                                <th> Method </th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminContributions.map((contribution) => (
                                <tr>
                                    <td> {contribution.member} </td>
                                    <td> {contribution.event} </td>
                                    <td> {contribution.amount} </td>
                                    <td> {contribution.date} </td>
                                    <td> {contribution.method} </td>
                                </tr>
                            ))}
                            {/* Add more table rows here */}
                        </tbody>
                    </table>
                    {adminContributions.length <= 0 &&
                        <div className='text-center text-dark mt-3'>
                            <h3>No contributions found</h3>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default AdminContributions;
