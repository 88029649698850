import React from 'react';
import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

function Biodata() {

    return (
        <div className='text-dark'>
            <p></p>
        </div>
    );
}

export default Biodata;
