import React from 'react';
import MemberEvents from './memberevents';
import { useState } from 'react';
import MemberContributions from './membercontributions';

function MemberDashboardContent({association, setAssociation, userMode, setuserMode, activeTab, setActiveTab, selectedEvents, setSelectedEvents, modalcontent, setModalcontent, toggle, events, setEvents, amounts, setAmounts, memberContributions, setMemberContributions}) {

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-sm-flex justify-content-between align-items-center transaparent-tab-border">
          <ul className="nav nav-tabs tab-transparent" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#" role="tab" aria-selected="true" onClick={()=> setActiveTab('events')}>Events</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="business-tab" data-bs-toggle="tab" href="#business-1" role="tab" aria-selected="false" onClick={()=> setActiveTab('contributions')}>Contributions</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="performance-tab" data-bs-toggle="tab" href="#" role="tab" aria-selected="false" onClick={()=> setActiveTab('perfomance')}>Performance</a>
            </li>
          </ul>
          <div className="d-md-block d-none">
            <a href="#" className="text-light p-1"><i classNamtse="mdi mdi-view-dashboard"></i></a>
            <a href="#" className="text-light p-1"><i className="mdi mdi-dots-vertical"></i></a>
          </div>
        </div>
        <div className="tab-content tab-transparent-content">
          <div className="tab-pane fade show active" id="business-1" role="tabpanel" aria-labelledby="business-tab">
            <div className="row">
                {activeTab === 'events' && <MemberEvents selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} modalcontent={modalcontent} setModalcontent={setModalcontent} toggle={toggle} events={events} setEvents={setEvents} amounts={amounts} setAmounts={setAmounts} />}
                {activeTab === 'contributions' && <MemberContributions memberContributions={memberContributions} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberDashboardContent;
