import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

function Navbar({ association, setAssociation, userMode, setuserMode, activeTab, biodata, loading, setLoading, setSelectedEvents, notifications, setModalcontent, toggle, setNotification }) {

  const handleChangeassociation = async (assoc) => {
    setSelectedEvents([]);
    setLoading(true);
    setuserMode('member');
    try {
      const token = await AsyncStorage.getItem('token');
      const data = {
        token: token,
        association: assoc.id
      }
      // Make POST request using Axios
      const response = await axios.post('https://api.edu-metrics.com/api/associations/switch/', data);
      if (response.data.success) {
        setAssociation(assoc);
      }
      else {
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const ViewNotification = (notification)=> {
    setNotification(notification);
    setModalcontent('notification');
    toggle();
  }


  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a className="navbar-brand brand-logo" style={{ color: 'white' }} href="index.html">BENEVOFY</a>
        <a className="navbar-brand brand-logo-mini" href="index.html"><img src="/images/logo-mini.svg" alt="logo" /></a>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="mdi mdi-menu"></span>
        </button>
        <div className="search-field d-none d-xl-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input type="text" className="form-control bg-transparent border-0" placeholder={`Search ${activeTab}`} />
            </div>
          </form>
        </div>
        <ul className="navbar-nav navbar-nav-right">
          {biodata.associations?.length > 1 &&
            <li className="nav-item  dropdown d-none d-md-block">
              <a className="nav-link dropdown-toggle" id="projectDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false"> Association </a>
              <div className="dropdown-menu navbar-dropdown" aria-labelledby="projectDropdown">
                {biodata.associations?.map((associationItem, index) => (
                  <div key={index}>
                    <a
                      className={association.name === associationItem.name ? "dropdown-item active bg-success text-white" : "dropdown-item"}
                      href="#"
                      onClick={() => handleChangeassociation(associationItem)}
                    >
                      {associationItem.name}
                    </a>
                    {index !== (biodata.associations?.length ?? 0) - 1 && <div className="dropdown-divider"></div>}
                  </div>
                ))}

              </div>
            </li>
          }
          {biodata.admin &&
            <li className="nav-item  dropdown d-none d-md-block">
              <a className="nav-link dropdown-toggle" id="projectDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false"> User Mode </a>
              <div className="dropdown-menu navbar-dropdown" aria-labelledby="projectDropdown">
                <a className={userMode === 'admin' ? ("dropdown-item active bg-success text-white") : ('dropdown-item')} href="#" onClick={() => setuserMode('admin')}>
                  <i className="mdi mdi-lock me-2"></i>Admin </a>
                <div className="dropdown-divider"></div>
                <a className={userMode === 'member' ? ("dropdown-item active bg-success text-white") : ('dropdown-item')} href="#" onClick={() => setuserMode('member')}>
                  <i className="mdi mdi-account me-2"></i>Member </a>
              </div>
            </li>
          }

          <li className="nav-item nav-language d-none d-md-block">
            <a className="nav-link" id="languageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="nav-language-icon">
                <i className="flag-icon flag-icon-ug" title="us" id="us"></i>
              </div>
              <div className="nav-language-text">
                <p className="mb-1 text-black">Ugandan</p>
              </div>
            </a>
          </li>
          <li className="nav-item nav-profile">
            <a className="nav-link" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <div className="nav-profile-img">
                <img src='/male.png' alt="image" />
              </div>
              <div className="nav-profile-text">
                <p className="mb-1 text-black">{biodata.firstname} {biodata.lastname}</p>
              </div>
            </a>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link count-indicator dropdown-toggle" id="messageDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="mdi mdi-email-outline"></i>
              <span className="count-symbol bg-success"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list" aria-labelledby="messageDropdown">
              <h6 className="p-3 mb-0 bg-primary text-white py-4">Messages</h6>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img src="/images/faces/face4.jpg" alt="image" className="profile-pic" />
                </div>
                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Mark send you a message</h6>
                  <p className="text-gray mb-0"> 1 Minutes ago </p>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img src="/images/faces/face2.jpg" alt="image" className="profile-pic" />
                </div>
                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Cregh send you a message</h6>
                  <p className="text-gray mb-0"> 15 Minutes ago </p>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img src="/images/faces/face3.jpg" alt="image" className="profile-pic" />
                </div>
                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 className="preview-subject ellipsis mb-1 font-weight-normal">Profile picture updated</h6>
                  <p className="text-gray mb-0"> 18 Minutes ago </p>
                </div>
              </a>
              <div className="dropdown-divider"></div>
              <h6 className="p-3 mb-0 text-center">4 new messages</h6>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
              <i className="mdi mdi-bell-outline"></i>
              <span className="count-symbol bg-danger"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
              <h6 className="p-3 mb-0 bg-dark text-white py-4">Notifications</h6>
              <div className="dropdown-divider"></div>
              {notifications.map((notification) => (
                <a href='#' onClick={()=> ViewNotification(notification)} className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i style={{color: 'white'}} className={notification.icon}></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">{notification.title}</h6>
                    <p className="text-gray ellipsis mb-0"> {notification.description} </p>
                  </div>
                </a>
              ))}

              <div className="dropdown-divider"></div>
              <h6 className="p-3 mb-0 text-center">See all notifications</h6>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
