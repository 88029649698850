import React, { useState } from 'react';

const ReportIssue = ({biodata, setIssue}) => {

    return (
        <form className="pt-3">
            <div className="form-group">
                <input type="text" className="form-control form-control-lg" readOnly value={biodata.email}  />
            </div>
            <div className="form-group">
                <textarea className="form-control form-control-lg" id="issueTextArea" style={{ height: '200px' }} placeholder="Help us improve the system by reporting issues encountered on the system." onChange={(e)=> setIssue(e.target.value)}></textarea>
            </div>
        </form>
    );
};

export default ReportIssue;
