import React, { useState, useEffect } from 'react';

const AddMember = () => {
    const [formState, setFormstate] = useState('new');

    return (
        <form className="pt-3">
            <div className="form-group">
                <select className="form-control form-control-lg" id="exampleInputEmail1" onChange={(e) => setFormstate(e.target.value)}>
                    <option value="new">Not in system</option>
                    <option value="exists">Exists</option>
                </select>
            </div>
            {formState === 'new' ? (
                <>
                    <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter First Name" />
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Last Name" />
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter Email" />
                    </div>
                </>
            ) : (
                <>
                    <div className="form-group">
                        <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter member email" />
                    </div>
                </>
            )}
        </form>
    );
};

export default AddMember;
