import React from 'react';
import { useState, useEffect } from 'react';

const MemberEvents = ({ selectedEvents, setSelectedEvents, modalcontent, setModalcontent, toggle, events, setEvents, amounts, setAmounts }) => {

  useEffect(() => {
    const updatedAmounts = selectedEvents.map(event => {
      // Check if event is in selectedEvents
      const isSelected = selectedEvents.some(selectedEvent => selectedEvent.id === event.id);
      // Return 0 or 1 based on whether the event is selected
      return event.minimum_contribution;
    });
    setAmounts(updatedAmounts);
  }, [selectedEvents]);

  const selectEvent = (event) => {
    const updatedSelectedEvents = [...selectedEvents];
    const eventIndex = updatedSelectedEvents.findIndex(e => e.id === event.id);

    if (eventIndex !== -1) {
      updatedSelectedEvents.splice(eventIndex, 1);
    } else {
      updatedSelectedEvents.push(event);
    }

    setSelectedEvents(updatedSelectedEvents);
  }

  const handlePay = () => {
    setModalcontent('pay');
    toggle();
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Available Events</h4>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> </th>
                  <th> Title </th>
                  <th> Contributions </th>
                  <th> Progress </th>
                  <th> Amount Required </th>
                  <th> Days Left </th>
                  <th> Closing Date </th>
                </tr>
              </thead>
              <tbody>
                {events.map((event) => (
                  <tr key={event.id}>
                    <td className="py-1">
                      {!event.paid_for && event.active &&
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={() => selectEvent(event)}
                          checked={selectedEvents.some(selectedEvent => selectedEvent.id === event.id)}
                        />
                      }
                    </td>
                    <td> {event.title} </td>
                    <td> {event.contributions} ({event.amount_paid}) </td>
                    <td>
                      <div className="progress">
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: event.progress + '%' }} aria-valuenow={event.progress} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                    <td> {event.amount_required} </td>
                    <td> {event.days_left} </td>
                    <td> {event.closing_date} </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {events.length <= 0 &&
              <div className='text-center text-dark mt-3'>
                <h3>No events found</h3>
              </div>
            }
          </div>
          {selectedEvents.length > 0 &&
            <div className='btn-group'>
              <a href="#" className="btn btn-sm btn-success mt-3" onClick={handlePay}>MOBILE</a>
              <a href="#" className="btn btn-sm btn-dark mt-3" onClick={handlePay}>CREDIT CARD</a>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default MemberEvents;
