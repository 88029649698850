import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AdminEvents = ({ events, setEvents, loading, setLoading, Refresh }) => {
    const [generating, setGenerating] = useState(false);

    const handleGetReport = async (event) => {
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                event: event.id
            };
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/events/report/', data, {
                responseType: 'blob' // Set responseType to 'blob' to receive binary data
            });
            // Create a blob object from the binary data received
            const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

            // Create a temporary URL for the blob object
            const url = window.URL.createObjectURL(blob);

            // Create a link element and click it to trigger the file download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', event.title + '_report.xlsx');
            document.body.appendChild(link);
            link.click();

            // Clean up
            window.URL.revokeObjectURL(url);

            console.log('Report generated and downloaded successfully');

        } catch (error) {
            console.error('Error:', error);
            // Handle errors
        }
    };

    const handleSwitchEvent = async (event) => {
        setLoading(true);
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                event: event.id
            }
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/events/switch/', data);
            if (response.data.success) {
                Refresh();
            }
            else {
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle errors
        }
    }

    const handleDeleteEvent = async (event) => {
        setLoading(true);
        try {
            const token = await AsyncStorage.getItem('token');
            const data = {
                token: token,
                event: event.id
            }
            // Make POST request using Axios
            const response = await axios.post('https://api.edu-metrics.com/api/events/delete/', data);
            if (response.data.success) {
                Refresh();
            }
            else {
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle errors
        }
    }


    return (
        <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Created Events</h4>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th> Status</th>
                                    <th> Title </th>
                                    <th> Contributions </th>
                                    <th> Progress </th>
                                    <th> Amount Required </th>
                                    <th> Closing Date </th>
                                    <th> Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {events.map((event) => (
                                    <tr key={event.id}>
                                        <td className="py-1">
                                            <div className="form-switch">
                                                <input className="form-check-input" checked={event.active} type="checkbox" id={`flexSwitchCheckChecked_${event.id}`} onClick={() => handleSwitchEvent(event)} />
                                            </div>
                                        </td>
                                        <td> {event.title} </td>
                                        <td> {event.contributions} </td>
                                        <td>
                                            <div className="progress">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: event.progress + '%' }} aria-valuenow={event.progress} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </td>
                                        <td> {event.amount_required} </td>
                                        <td> {event.closing_date} </td>
                                        <td>
                                            <div className="btn-group">
                                                <button onClick={() => handleGetReport(event)} className="btn btn-sm btn-success btn-icon-text"><i class="mdi mdi-file-excel btn-icon-prepend"></i>Report</button>
                                                {event.contributions <= 0 &&
                                                    <button className="btn btn-sm btn-danger btn-icon-text" onClick={()=> handleDeleteEvent(event)}><i class="mdi mdi-delete-sweep btn-icon-prepend"></i>Delete</button>
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                ))}
                                {/* Add more table rows here */}
                            </tbody>
                        </table>
                        {events.length <= 0 &&
                            <div className='text-center text-dark mt-3'>
                                <h3>No events found</h3>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminEvents;
