import React from 'react';

const MemberContributions = ({ memberContributions }) => {
  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Your Contributions</h4>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th> Event </th>
                  <th> Amount </th>
                  <th> Date </th>
                  <th> Method </th>
                </tr>
              </thead>
              <tbody>
                {memberContributions.map((contribution, index) => (
                  <tr key={index}>
                    <td> {contribution.event} </td>
                    <td> {contribution.amount} </td>
                    <td> {contribution.date} </td>
                    <td> {contribution.method} </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {memberContributions.length <= 0 &&
              <div className='text-center text-dark mt-3'>
                <h3>No contributions found</h3>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberContributions;
